import React from 'react'

import styles from './TimelinePanel.scss'
import TimelineListItem from './TimelineListItem'
import { showTime } from './timehelp'
import IconButton from 'components/Commons/IconButton'

const TimelineList = ({ data, setData, height, domRef, timelineState, cursorTime, changeScale }) => {
  return (
    <div
      ref={domRef}
      style={{ overflow: 'overlay', height }}
      onScroll={e => {
          const { target } = e
          timelineState.current.setScrollTop(target.scrollTop)
      }}
      className={styles['timeline-list']}
    >
      <div className={styles['timeline-list-item-header']}>
        <IconButton type="minus-square" className={styles.scaleButton} onClick={() => changeScale(+1)} tooltip="Zoom out" />
        <IconButton type="plus-square" className={styles.scaleButton} onClick={() => changeScale(-1)} tooltip="Zoom in" />
        <div className={styles.time}>{showTime(cursorTime)}</div>
      </div>
      {/* key is compound of id and collapsed state so the collapse state is reset after setData is called too */}
      {data.map(item => <TimelineListItem key={item.id} item={item} setData={setData} data={data} />)}
    </div>
  )
}

export default TimelineList
