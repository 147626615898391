
import React from 'react'
import { connect } from 'react-redux'
import { applySpec } from 'ramda'
import { compose } from 'recompose'

import { panes as panesSelector } from 'selectors/view'
import Panes, { Disposition } from 'preferences/Panes'

import ViewPanel from 'components/ViewPanel/ViewPanel'
import ViewPanelPane from 'components/ViewPanel/ViewPanelPane/ViewPanelPane'

import CurrentNodePlayback from '../PlaybackView/CurrentPlayback/CurrentNodePlayback'

import styles from './BottomPanel.scss'

const FullScreenWrapper = ({ children }) => (
  <div className={styles.fullScreenPlaybackPanel}>
    {children}
  </div>
)

export const BottomPanel = ({ panes }) => (
  <div className={styles.bottomPanel}>
    <ViewPanel
      pane={panes.horizontal}
      paneName={Disposition.horizontal}
      className={styles.bottomViewPanel}
      modalFooter={<CurrentNodePlayback nosound />}
      fullScreenWrapper={FullScreenWrapper}
      >
      {Object.values(Panes.horizontal).map(pane => (
        <ViewPanelPane id={pane.id} key={pane.id} pane={pane} />
      ))}
    </ViewPanel>
  </div>
)

export default compose(
  connect(applySpec({
    panes: panesSelector
  })),
)(BottomPanel)