import React, { useCallback, useMemo, useState } from 'react'
import classNames from 'classnames'
import { equals } from 'ramda'

import { useSelector } from 'react-redux'
import { selectedObject } from 'selectors/objects'
import { guard } from 'utils/math'
import { traverseToBeginning } from 'selections/graph'
import { objectsIndex } from 'selectors/apollo'
import NoContent from 'components/Commons/NoContent'

import Timeline from './Timeline'

import timelinePanelStyles from './TimelinePanel.scss'

// TODO: move scale change with a -/+ buttons to the view panel
const SCALES = [1, 2, 3, 5, 8, 13]
const guardedIndex = guard(0, SCALES.length - 1)
const DEFAULT_SCALE_INDEX = 3

/**
 * The bottom panel that shows timelines
 * it's a mockup right now
 */
const _TimelinePanel = () => {
  const index = useSelector(objectsIndex)
  const selected = useSelector(selectedObject)
  const timelineId = useMemo(() => {
    if (!selected) return
    // TODO: use constant
    if (selected.sys === 'timeline') {
      const timeline = traverseToBeginning(index)(index[selected.id])
      return timeline.id
    } else if (selected.sys === 'clip') {
      return selected?.data?.user?.timeline
    }
  }, [index, selected])
  const [scale, setScale] = useState(SCALES[DEFAULT_SCALE_INDEX])
  const changeScale = useCallback(delta => {
    const currentIdx = SCALES.findIndex(equals(scale))
    const newIdx = currentIdx + delta
    const guardedIdx = guardedIndex(newIdx)
    setScale(SCALES[guardedIdx])
  }, [scale, SCALES])

  return (
    !timelineId ?
      <NoContent message="No timeline for this object" className={timelinePanelStyles.noContent} />
      :
      (
        <div className={timelinePanelStyles.timelinePanel}>
          <div className={classNames(timelinePanelStyles['timeline-editor-engine'])}>
            <Timeline key={timelineId} timelineId={timelineId} scale={scale} changeScale={changeScale} />
          </div>
        </div>
      )
  )
}

export default _TimelinePanel