import { useState, useLayoutEffect } from 'react'
import useResizeObserver from '@react-hook/resize-observer'

import useDebouncedCallback from '../react/useDebouncedCallback'

export const useSize = (targetRef, debounce = 200) => {

  const [size, setSize] = useState()
  const onResize = entry => setSize(entry.contentRect.height)

  const debouncedOnResize = useDebouncedCallback(onResize, [setSize], debounce)

  useLayoutEffect(() => {
    setSize(targetRef.current.getBoundingClientRect())
  }, [targetRef])

  useResizeObserver(targetRef, debouncedOnResize)
  return size
}
