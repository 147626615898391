import React, { createRef } from 'react'
import { connect } from 'react-redux'
import { compose, withStateHandlers, withProps, withHandlers } from 'recompose'
import { withRef } from 'utils/recompose'
import { pathEq, over, assoc, lensProp } from 'ramda'
import classNames from 'classnames'
import { Modal } from 'antd'
import styles from './ViewPanel.scss'

import ViewHeader from './ViewHeader'
import ViewControls from './ViewControls'
import DefaultFullscreenWrapper from './DefaultFullscreenWrapper'
import MinimizedViewPanel from './MinimizedViewPanel'
import { ViewPanelVisibleContentContext } from './ViewPanelContexts'

import { setCurrentTab, minimizeSecondaryPane } from 'actions/view'
import { Disposition } from 'preferences/Panes'

const viewPanelVisibleContentRef = createRef()

const ViewPanelVisibleContent = ({ activeChild, onFocus }) => {
  return (
    <div ref={viewPanelVisibleContentRef} className={classNames(styles.content, activeChild.props.pane.contentClassName)} onFocus={onFocus} role="button" tabIndex={0}>
      <ViewPanelVisibleContentContext.Provider value={viewPanelVisibleContentRef}>
        {activeChild}
      </ViewPanelVisibleContentContext.Provider>
    </div>
  )
}

// TODO: this component still needs a lot of refactoring
// - split into smaller components
// - extract behavior to recompose

class ViewPanel extends React.Component {

  componentDidMount() {
    const { setDisposition, getOtherPanel } = this.props
    const otherPanel = getOtherPanel()
    const disposition = otherPanel ? otherPanel.classList.contains(Disposition.horizontal) ? Disposition.horizontal : Disposition.vertical : Disposition.vertical
    setDisposition(disposition)
  }

  render() {
    const { domNode, pane, fullScreen } = this.props
    return (
      <div ref={domNode()} className={styles.container}>
        {(fullScreen ? this.renderFullScreen : pane?.minimized ? this.renderMinimized : this.renderVisible)()}
      </div>
    )
  }
  renderFullScreen = () => {
    const { toggleFullScreen, fullScreen, modalFooter, fullScreenWrapper: FullscreenWrapper = DefaultFullscreenWrapper } = this.props
    const activeChild = this.getActiveChild()
    return (
      <Modal
        visible
        footer={modalFooter || null}
        wrapClassName={styles.fullScreenModal}
        width="90%"
        onCancel={toggleFullScreen}
      >
        <FullscreenWrapper>
          <div className={styles.fullScreenContent}>
            <ViewControls controls={activeChild.props.pane.controls} styles={styles} fullScreen={fullScreen} />
            {activeChild}
          </div>
        </FullscreenWrapper>
      </Modal>
    )
  }
  renderMinimized = () => (
    <MinimizedViewPanel
      className={this.props.className}
      activeChild={this.getActiveChild()}
      disposition={this.props.disposition}
      styles={styles}
      selectView={this.selectView}
      maximize={this.maximize}
    >
      {this.props.children}
    </MinimizedViewPanel>
  )
  getActiveChild = () => {
    const { children, pane } = this.props
    const active = React.Children.toArray(children).find(pathEq(['props', 'pane', 'id'], pane?.currentTab))
    return active ? React.cloneElement(active, { provideController: this.setActiveChild }) : children[0]
  }
  setActiveChild = child => { this.activeChild = child }

  renderVisible = () => {
    const { children, className, footer: footerChild, disposition, toggleFullScreen, fullScreen } = this.props

    const activeChild = this.getActiveChild()

    return (
      <div className={classNames(styles.panel, className, styles[disposition])}>
        <ViewHeader
          {...{ styles, disposition, activeChild }}
          toggleFullScreen={toggleFullScreen}
          minimize={this.minimize}
          selectView={this.selectView}
          fullScreen={fullScreen}
        >
          {children}
        </ViewHeader>
        <ViewPanelVisibleContent
          activeChild={activeChild}
          onFocus={this.focusContent}
        />

        { footerChild &&
          <div>{footerChild}</div>
        }
      </div>
    )

  }
  selectView = (activeView, maximize = false) => {
    const { setCurrentTabAction, paneName } = this.props
    setCurrentTabAction(paneName, activeView)
    if (maximize) {
      this.maximize()
    }
  }
  minimize = () => {
    const { minimizeSecondaryPaneAction, paneName, toggle } = this.props
    minimizeSecondaryPaneAction(paneName, true)
    toggle('calc(100% - 50px)')
  }
  maximize = () => {
    const { minimizeSecondaryPaneAction, paneName, pane, toggle } = this.props
    toggle(`${pane.size}px`)
    minimizeSecondaryPaneAction(paneName, false)
  }

  focusContent = () => {
    if (this.activeChild) {
      this.activeChild.onFocus()
    }
  }

}


export default compose(
  connect(null, ({
    setCurrentTabAction: setCurrentTab,
    minimizeSecondaryPaneAction: minimizeSecondaryPane
  })),
  withStateHandlers({ disposition: Disposition.horizontal, fullScreen: false }, {
    toggleFullScreen: prev => () => over(lensProp('fullScreen'), _ => !_, prev),
    setDisposition: prev => value => assoc('disposition', value, prev)
  }),
  withProps(({ disposition }) => ({
    propToAdjust: disposition === Disposition.horizontal ? 'height' : 'width'
  })),
  withRef('domNode'),
  withHandlers(() => ({
    getOtherPanel: ({ domNode }) => () => domNode().current.parentNode.parentNode.parentNode.querySelector('.Pane1')
  })),
  withHandlers({
    toggle: ({ propToAdjust, getOtherPanel }) => value => {
      getOtherPanel().style[propToAdjust] = value
    }
  })
)(ViewPanel)

