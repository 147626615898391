import { EMPTY_ARRAY, toArray } from 'utils/object'
import { model, parseRef } from 'beanie-engine-api-js'

const { types: { node: { getChildId } } } = model

//
// Range selection resolution
//
export const nodeTraverseCollectingIds = index => (startNode, endNode) => {
  const result = findInChildren(index)(endNode, startNode)
  let ids = result.path || []
  if (!ids.includes(endNode.id)) {
    // we find out that the ending node was in the other direction
    ids = []
    visitNodeChainUpstream(index)(node => ids.push(node.id))(startNode, endNode)
    if (!ids.includes(endNode.id)) return EMPTY_ARRAY
  }
  return ids
}

export const traverseToEnd = index => startNode => findInChildren(index, true)({}, startNode).path

export const traverseToBeginning = index => startNode => {
  const ids = []
  visitNodeChainUpstream(index)(node => ids.push(node.id))(startNode, {})
  return ids
}

export const findInChildren = (index, firstPath = false) => (targetNode, currentNode) => {
  if (currentNode.id === targetNode.id) {
    return { result: currentNode, path: [currentNode.id] }
  }
  const childId = getChildId(currentNode)
  if (!childId) return { result: undefined, path: [currentNode.id] }
  const child = index[childId]
  if (!child) return { result: undefined, path: [currentNode.id] }

  // look in child
  const tmp = findInChildren(index, firstPath)(targetNode, child)
  const condition = firstPath ? tmp.path : tmp.result
  if (condition) {
    tmp.path.unshift(currentNode.id)
    return tmp
  } else {
    return { result: undefined, path: [currentNode.id] }
  }
}

export const visitNodeChainUpstream = index => _visitNodeChain(index)('parent')

const getNext = index => (node, propName) => {
  const value = node.data[propName]
  if (!value) return EMPTY_ARRAY
  return toArray(value).map(id => (id.id ? id : index[parseRef(id)]))
}

const _visitNodeChain = index => propName => onVisit => (startNode, endNode) => {
  const doVisit = _visitNodeChain(index)(propName)(onVisit)
  if (onVisit) onVisit(startNode)
  if (startNode.id === endNode?.id) return

  const nextNodes = getNext(index)(startNode, propName)
  if (nextNodes) {
    nextNodes.forEach(nextNode => {
      doVisit(nextNode, endNode)
    })
  }
}