import React, { useCallback, useMemo, useState } from 'react'
import { always, assoc, cond, equals, identity, lensIndex, over, pipe, prop, T } from 'ramda'
import { Icon } from 'antd'

import styles from './TimelinePanel.scss'

const resourceIcon = pipe(
  prop('resourceType'),
  cond([
    [equals('clip'), always('video-camera')],
    [equals('fmod'), always('sound')],
    [T, always('question')]
  ])
)

const opsOnArray = (data, parentId, collapsed, ops = []) => {
  data.forEach((i, idx) => {
    if (i.parentId === parentId) {
      ops.push({ idx, collapsed })
      opsOnArray(data, i.id, collapsed, ops)
    }
  })
  return ops
}

const baseAlpha = 10
const stepAlpha = 20
const getItemColor = (depth, isResource) => {
  const alpha = Math.max((depth * 10) + (!isResource ? stepAlpha : 11), baseAlpha)
  // return black hex with dynamic alpha according to depth. more depth more opacity/black
  return `#000000${alpha.toString(16)}`

}
const getItemPadding = (depth, isResource) => {
  const basePadding = isResource ? 16 : 20
  return (6 * depth) + basePadding
}

const TimelineListItem = ({ item, data, setData }) => {
  const [isCollapsing, setCollapsing] = useState(item.collapsed || false)
  const isResource = useMemo(() => item.type === 'resource', [item])
  const isEvent = useMemo(() => item.type === 'events', [item])

  const toggleCollapse = useCallback(() => {

    setCollapsing(!isCollapsing)

    const ops = opsOnArray(data, item.id, !isCollapsing)
    const newData = pipe(
      identity,
      ...ops.map(({ idx, collapsed }) => over(lensIndex(idx), assoc('collapsed', collapsed)))
    )(data)
    setData(newData)
  }, [setData, data, item])

  const style = {
    display: `${item.collapsed ? 'none' : 'flex'}`,
    justifyContent: isEvent ? 'flex-start' : 'space-between',
    paddingLeft: `${getItemPadding(item.depth, isResource)}px`,
    backgroundColor: `${getItemColor(item.depth, isResource)}`,
    ...isResource &&
      {
        cursor: 'pointer',
        ...!isCollapsing && { borderBottom: '1px solid #0000000F' }
      }
  }
  return (
    <div style={style} className={styles['timeline-list-item']} key={item.id} onClick={toggleCollapse}>
      { isResource && <Icon type={resourceIcon(item)} /> }
      <span className={styles.text}>{`${item.name}`}</span>
      { isResource && <Icon type="caret-down" style={{ transform: `rotate(${isCollapsing ? '270deg' : '0deg'})` }} /> }
    </div>
  )
}

export default TimelineListItem
