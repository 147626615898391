import React from 'react'
import { always } from 'ramda'
import { isDeveloperCheck } from 'security/platform'
import { isUndoRedoEnabled } from 'model/features'

import TimelinePanel from '../components/EditProjectSection/Panels/Timeline/TimelinePanel'
import ConsolePanel from '../components/EditProjectSection/Panels/Console/ConsolePanel'
import ConsolePanelControls from '../components/EditProjectSection/Panels/Console/ConsolePanelControls'
import PropertiesPanel from '../components/EditProjectSection/Panels/PropertiesPanel'

import {
  PLAYBACK,
  PINS,
  TEXT,
  STATE,
  SEARCH,
  EXECUTIONS,
  PROBLEMS,
  CHAIN,
  PROPERTIES,
  DEV_PROPERTIES,
  OBJECT_HISTORY,
  VARIABLES,
  UNDO,
  CONSOLE,
  TIMELINE,
  TASKS
} from './PaneIds'

// panels (bottom)
import ProjectState from 'components/ProjectStatePanel/ProjectStatePanel'
import StateControls from 'components/State/StateControls'

import PlaybackView from 'components/PlaybackView/PlaybackView'
import PlaybackViewControls from 'components/PlaybackView/PlaybackControls/PlaybackViewControls'

import ChainExecutionsView from 'components/ChainExecutionsView/ChainExecutionsView'
import ChainExecutionsViewControls from 'components/ChainExecutionsView/ChainExecutionsViewControls'

import ProjectProblems from 'components/ProjectProblems/ProjectProblems'
import ProblemsControls from 'components/ProjectProblems/ProblemsControls'
import ProblemsTitle from 'components/ProjectProblems/ProblemsTitle'

import SearchViewPanel from 'components/SearchViewPanel/ConnectedSearchViewPanel'
import SeachViewControls from 'components/SearchViewPanel/SeachViewControls'
import PinsPanel from 'components/Debugging/PinsPanel'
import UserTasksPanel from 'components/UserTasksPanel/UserTasksPanel'

// panels (right)
import BneNodePropertiesEditor from 'components/BneObjectPropertiesEditor/BneNodePropertiesEditor'
import TextView from 'components/TextView/TextView'
import TextEditorControls from 'components/TextEditor/TextEditorControls/TextEditorControls'
import NodeState from 'components/State/NodeState'
import TextEditorForSelectedNode from 'components/TextEditorForSelectedNode/TextEditorForSelectedNode'
import UndoHistory from 'components/UndoHistory/UndoHistory'
import UndoHistoryControls from 'components/UndoHistory/UndoHistoryControls'
import SelectedObjectHistory from 'components/ObjectHistory/SelectedObjectHistory'

import rightStyles from 'components/EditProjectSection/RightPanel.scss'

export const Disposition = {
  horizontal: 'horizontal',
  vertical: 'vertical'
}

export const Panes = {
  horizontal: {
    Playback: {
      id: PLAYBACK,
      component: PlaybackView,
      controls: PlaybackViewControls,
    },
    State: {
      id: STATE,
      component: ProjectState,
      controls: StateControls,
    },
    Search: {
      id: SEARCH,
      component: SearchViewPanel,
      controls: SeachViewControls
    },
    Pins: {
      id: PINS,
      component: PinsPanel
    },
    Executions: {
      id: EXECUTIONS,
      visibleOn: isDeveloperCheck,
      component: ChainExecutionsView,
      controls: ChainExecutionsViewControls,
    },
    Problems: {
      id: PROBLEMS,
      visibleOn: isDeveloperCheck,
      component: ProjectProblems,
      controls: ProblemsControls,
      title: ProblemsTitle
    },
    Console: {
      id: CONSOLE,
      visibleOn: isDeveloperCheck,
      component: ConsolePanel,
      controls: ConsolePanelControls,
      title: <div>Console</div>
    },
    Timeline: {
      id: TIMELINE,
      visibleOn: isDeveloperCheck,
      component: TimelinePanel,
      title: <div>Timeline</div>
    },
    Tasks: {
      id: TASKS,
      title: <div>Tasks</div>,
      component: UserTasksPanel
    }
  },
  vertical: {
    Chain: {
      id: CHAIN,
      title: <div>Text<small>(chain)</small></div>,
      component: TextView,
      controls: TextEditorControls,
    },
    Text: {
      id: TEXT,
      component: TextEditorForSelectedNode,
      controls: TextEditorControls,
      contentClassName: rightStyles.singleNodeTextEditor,
    },
    Properties: {
      id: PROPERTIES,
      component: PropertiesPanel,
      contentClassName: rightStyles.propertiesEditorInEdit,
    },
    Object: {
      id: DEV_PROPERTIES,
      title: 'object',
      visibleOn: isDeveloperCheck,
      component: BneNodePropertiesEditor,
      contentClassName: rightStyles.propertiesEditorInEdit,
    },
    Variables: {
      id: VARIABLES,
      component: NodeState,
    },
    ObjectHistory: {
      id: OBJECT_HISTORY,
      title: 'history',
      // TODO: fix ObjectHistory performance problems and set back to always visible
      visibleOn: isDeveloperCheck,
      component: SelectedObjectHistory,
      contentClassName: rightStyles.objectHistory,
    },
    Undo: {
      id: UNDO,
      component: UndoHistory,
      controls: UndoHistoryControls,
      visibleOn: always(isUndoRedoEnabled)
    }
  },
}

export default Panes
