import { defaultTo, flatten, flip, isEmpty, map, path, pipe, prop } from 'ramda'
import { nanoid } from 'nanoid'

import { safeId } from 'model/utils'
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'utils/object'

// creating new ids on some elements is necessary for collaps/expand group logic
const timelineElementId = () => `transientid-${nanoid(4)}`

const getTimelineStart = tl => (tl?.data?.block?.start_time || 0) + (tl?.data?.translate || 0)
const getTimelineEnd = tl => (tl?.data?.block?.end_time || 0) + (tl?.data?.translate || 0)

const getKeyTime = (tl, time) => {
  // TODO: use path
  return (tl?.data?.translate || 0) + time
}

const timelineToAction = (tl, { isRoot = false, actionType = 'base' } = {}) => ({
  id: isRoot ? `${tl.id}_base` : tl.id,
  start: getTimelineStart(tl),
  end: getTimelineEnd(tl),
  flexible: !isRoot,
  movable: !isRoot,
  data: {
    name: tl.data.res_name,
    actionType,
  }
})
const controlToAction = (timeline, key, control) => {
  // TODO: change to Paths
  return control.map(({ time, interp, value }) => ({
    start: getKeyTime(timeline, time),
    end: getKeyTime(timeline, time),
    flexible: false,
    movable: false,
    data: {
      actionType: 'controlkey',
      control: key,
      time,
      interp,
      value
    }
  }))
}

const baseTimelineActions = (index, timeline) => ([
  timelineToAction(timeline, { isRoot: true }),
  // ...eventsToActions(timeline, { isRoot: true })

  // TODO: add more actions for: fade in/out and trim in/out. these will be movable but limited with min/max
  // probably sharing the same TimelineRow and NOT flexible, so they are kind of keyframes.
  // also keep in mind that fade out can't be earlier than fade ind (same for trim), not sure how to check that
])

const controlTimelineRows = (index, timeline, depth) => {
  const controlValues = (timeline?.data?.control_values || EMPTY_OBJECT)

  return Object.keys(controlValues)
    .map(controlName => {
      return {
        id: timelineElementId(),
        type: 'control',
        name: controlName,
        actions: controlToAction(timeline, controlName, controlValues[controlName]),
        parentId: timeline.id,
        depth,
        classNames: ['timeline-row-type-control']
      }
    })
}

// returns empty array or length 1 array
const eventTimelineRow = (timeline, depth) => {
  const actions = eventsToActions(timeline)
  if (isEmpty(actions)) return EMPTY_ARRAY

  return [{
    id: timelineElementId(),
    type: 'events',
    name: 'events',
    actions,
    parentId: timeline.id,
    depth,
    classNames: ['timeline-row-type-control']
  }]
}


const eventsToActions = timeline => (timeline?.data?.events || EMPTY_ARRAY).map(({ time, event_data }) => ({
  // id: `${timeline.id}_event`,
  start: getKeyTime(timeline, time),
  end: getKeyTime(timeline, time),
  flexible: false,
  movable: false,
  data: {
    actionType: 'event',
    time,
    eventData: event_data
  }
}))

const childTimelineRows = (index, timeline, depth) => pipe(
  path(['data', 'children']),
  defaultTo([]),
  map(
    pipe(
      safeId,
      flip(prop)(index),
      modelToUi(index, depth + 1)
    )
  )
)(timeline)

export const modelToUi = (index, depth = 0) => timeline => {
  const parentId = safeId(path(['data', 'parent'], timeline))
  return ([
    {
      id: timeline.id,
      type: 'resource',
      resourceType: timeline.data.res_type,
      name: timeline.data.res_name || timeline.data.res_handler,
      actions: baseTimelineActions(index, timeline),
      ...parentId && { parentId },
      depth,
      classNames: ['timeline-row-type-resource']
    },
    ...flatten(eventTimelineRow(timeline, depth)),
    ...flatten(controlTimelineRows(index, timeline, depth)),
    ...flatten(childTimelineRows(index, timeline, depth)),
  ])
}

export const uiToModel = () => {

}