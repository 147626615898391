import React from 'react'
import { Tooltip } from 'antd'
import { always } from 'ramda'

import { createLabelSelector } from 'selectors/labels'
import useSelectorMaker from 'hooks/selectors/useSelectorMaker'

import { showTime } from '../timehelp'

const getBgColor = action => {

  if (action.data.value === true) return '#096DD9'
  if (action.data.value === false) return '#011B53'
  return '#434343'
}

const TooltipTitle = ({ label, action: { value, start, end, data } }) => (
  <div>
    <p>{showTime(start)}{start !== end ? ` - ${showTime(end)}` : ''}</p>
    <p>{label?.name || (value === '' ? ' ' : value)}</p>
    { data?.eventData && <pre>{JSON.stringify(data?.eventData, null, 2)}</pre> }
  </div>
)

export const StepKeyframe = ({ action }) => {
  const { data: { value } } = action
  // TODO: not sure how to get rid of a "Reference to non-existent object" warn that prints after executing next line
  const label = useSelectorMaker(createLabelSelector, [always(value)])
  const bottom = typeof value === 'boolean' ? (value ? -8 : -30) : -19
  return (
    <div className="keyframe-step">
      <div style={{ position: 'relative', height: '80%' }}>

        <Tooltip title={<TooltipTitle label={label} action={action} />}>
          <div
            className="tooltip"
            style={{
                width: 7,
                height: 7,
                backgroundColor: getBgColor(action),
                borderRadius: 1,
                position: 'absolute',
                bottom,
                left: -3,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                transform: 'rotate(135deg)'
            }}>
            {/* <span className="tooltiptext">{action.data.value}</span> */}
          </div>
        </Tooltip>

      </div>
    </div>
  )
}